import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

const cursor = document.querySelector(".cursor-ball");
const cursorFollower = document.querySelector(".cursor-ball-follower");
const clickable = document.querySelectorAll("button, a, input, textarea");

    clickable.forEach((element) => {
		element.addEventListener("mouseenter", () => {
			cursor.classList.add("button-hover");
			cursorFollower.classList.add("button-hover");
		});

		element.addEventListener("mouseleave", () => {
			cursor.classList.remove("button-hover");
			cursorFollower.classList.remove("button-hover");
		});
	});

	document.addEventListener("mousemove", (e) => {
		cursor.style.left = `${e.clientX}px`;
		cursor.style.top = `${e.clientY}px`;
		cursorFollower.style.left = `${e.clientX}px`;
		cursorFollower.style.top = `${e.clientY}px`;
	});

	document.addEventListener("mousedown", () => {
		cursorFollower.classList.add("click");
	});

	document.addEventListener("mouseup", () => {
		cursorFollower.classList.remove("click");
	});
